import { gql, useQuery } from '@apollo/client';
import { Entitlement } from '@/types/entitlement';

const ENTITLEMENTS_QUERY = gql`
  query EntitlementsObject {
    entitlementsObject @client
  }
`;

function getEntitlementValue(
  entitlements: Entitlement[],
  entitlementKey: string,
): boolean | number {
  const hasValue = Object.prototype.hasOwnProperty.call(entitlements, entitlementKey);

  return hasValue ? entitlements?.[entitlementKey] : false;
}

/**
 * @returns {Function} getEntitlement
 */
export default function useEntitlements(): (key: string) => boolean | number {
  const { data } = useQuery(ENTITLEMENTS_QUERY);

  function getEntitlement(entitlementKey: string) {
    return getEntitlementValue(data.entitlementsObject, entitlementKey);
  }

  return getEntitlement;
}
